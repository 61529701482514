// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bodycomposition-js": () => import("./../../../src/pages/bodycomposition.js" /* webpackChunkName: "component---src-pages-bodycomposition-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-contactme-js": () => import("./../../../src/pages/contactme.js" /* webpackChunkName: "component---src-pages-contactme-js" */),
  "component---src-pages-csoportos-fitnesz-js": () => import("./../../../src/pages/csoportos-fitnesz.js" /* webpackChunkName: "component---src-pages-csoportos-fitnesz-js" */),
  "component---src-pages-fitnesz-szolgaltatasok-js": () => import("./../../../src/pages/fitnesz-szolgaltatasok.js" /* webpackChunkName: "component---src-pages-fitnesz-szolgaltatasok-js" */),
  "component---src-pages-fitnesz-tabor-js": () => import("./../../../src/pages/fitnesz-tabor.js" /* webpackChunkName: "component---src-pages-fitnesz-tabor-js" */),
  "component---src-pages-gabi-fitnesz-blog-index-js": () => import("./../../../src/pages/gabi-fitnesz-blog/index.js" /* webpackChunkName: "component---src-pages-gabi-fitnesz-blog-index-js" */),
  "component---src-pages-gabi-fitnesz-blog-mdx-slug-js": () => import("./../../../src/pages/gabi-fitnesz-blog/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-gabi-fitnesz-blog-mdx-slug-js" */),
  "component---src-pages-gabi-rolam-mondtak-index-js": () => import("./../../../src/pages/gabi-rolam-mondtak/index.js" /* webpackChunkName: "component---src-pages-gabi-rolam-mondtak-index-js" */),
  "component---src-pages-gabi-rolam-mondtak-mdx-slug-js": () => import("./../../../src/pages/gabi-rolam-mondtak/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-gabi-rolam-mondtak-mdx-slug-js" */),
  "component---src-pages-gyerek-fitnesz-js": () => import("./../../../src/pages/gyerek-fitnesz.js" /* webpackChunkName: "component---src-pages-gyerek-fitnesz-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kovacs-gabi-bemutatkozas-js": () => import("./../../../src/pages/kovacs-gabi-bemutatkozas.js" /* webpackChunkName: "component---src-pages-kovacs-gabi-bemutatkozas-js" */),
  "component---src-pages-online-fitnesz-js": () => import("./../../../src/pages/online-fitnesz.js" /* webpackChunkName: "component---src-pages-online-fitnesz-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-szemelyi-edzesek-js": () => import("./../../../src/pages/szemelyi-edzesek.js" /* webpackChunkName: "component---src-pages-szemelyi-edzesek-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

